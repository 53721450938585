import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faLaptopCode, faMobileAndroidAlt, faHandPointUp, faDatabase, faArrowRight, faPaintBrush, faCode, faBullseye, faHandsHoldingCircle, faBrush} from '@fortawesome/free-solid-svg-icons'
import { FaInstagram, FaEnvelope, FaPhone, FaFacebookF, FaLinkedin } from 'react-icons/fa'
import './App.css';
import React, { Link, useState, useEffect, useRef} from 'react';
import {logo, logoBanner, banner, user1, bgSection, bgContact, logoSectionContact} from './components/img'
import emailjs from '@emailjs/browser';



function App() {
    const form = useRef();
    const [myNavbar, setNavbar] = useState(true);
    const [offset, setOffset] = useState(0);
    const [myNumber, setNumber] =  useState(0);

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_09j1thp', 'template_f546al9', form.current, 'usTXkMeaH4-cA4jC4')
          .then((result) => {
              
              console.log(result.text);

          }, (error) => {
              console.log(error.text);
          });
      };

    const styles = {
        navbarCollapse: {
            transition: 'height 0.35s ease',
          },
          textNavbar: {
            transition: 'all 0.35s ease',
          },
    } 
    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);

        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    console.log(offset); 
  
  
  return (
    <>
<div className='scroll-smooth'>
    <div className='sticky top-0 z-50 w-full'>
    <section className='bg-black items-center h-[50px] lg:flex hidden'>
        <div className='max-w-[1100px] mx-auto w-full h-full flex justify-between text-white '>
            <div className='h-full'>
                <ul className='flex items-center h-full space-x-4'>
                    <a href='https://www.instagram.com/technorider.id/'>
                        <li className='rounded-full h-[25px] w-[25px] text-[16px]  flex items-center justify-center hover:bg-red-500'>
                            < FaInstagram/>
                        </li>
                    </a>
                    <a href='https://www.instagram.com/technorider.id/'>
                        <li className='rounded-full h-[25px] w-[25px] text-[16px]  flex items-center justify-center hover:bg-blue-500'>
                                < FaFacebookF/>
                        </li>
                    </a>
                    <a href='https://www.instagram.com/technorider.id/'>
                        <li className='rounded-full h-[25px] w-[25px] text-[16px]  flex items-center justify-center hover:bg-blue-700'>
                                < FaLinkedin/>
                        </li>
                    </a>
                    
                </ul>
            </div>
            <div className='h-full flex space-x-4 text-[12px]'>
                <div className='flex items-center h-full'>
                <a className='flex hover:underline'href="mailto:info@technorider.id">
                    <FaEnvelope className='mr-1 text-[16px]'/>
                    
                    info@technorider.id</a>
                </div>
                <div className='flex items-center h-full'>
                <a className='flex hover:underline' href="tel:+62852657880033">
                    <FaPhone className='mr-1 text-[16px] rotate-90'/>
                    +62852657880033
                </a>
                </div>
            </div>
        </div>
    </section>
    <section  className="sticky top-0 w-full h-auto bg-[#333] z-50">
        <nav style={{...styles.navbarCollapse,
          height: offset < 200 ? '100px' :'70px' }} className="transition w-full mx-auto max-w-[1100px] lg:block hidden">
            <div className="flex items-center h-full justify-between">
                
                <img className="h-[40px]" src={logo} alt="" loading="lazy" />
                <div className="text-[20px] font-bold text-white uppercase">
                    <ul className="flex items-center">
                    <li className="pl-[22px]">
                            <a  href="#company" className='hover:text-[#ff2200] duration-300 transition-all'>
                                our company
                            </a>
                        </li>
                        <li className="pl-[22px]">
                            <a  href="#services" className='hover:text-[#ff2200] duration-300 transition-all'>
                                our services
                            </a>
                        </li>
                        <li className="pl-[22px]">
                            <a href="#process" className='hover:text-[#ff2200] duration-300 transition-all'>
                                out process
                            </a>
                        </li>
                        <li className="pl-[22px]">
                            <a href="#contact" className='hover:text-[#ff2200] duration-300 transition-all'>
                                contact us
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <nav className="lg:hidden h-[80px] w-full ">
            <div className="flex items-center justify-between h-full max-w-[340px] mx-auto">
                <img className="h-[30px] w-auto" src={logo} alt=""/>
                <div>
                    <button className="" onClick={() => setNavbar(!myNavbar)}>
                    <FontAwesomeIcon className="text-lg text-white" icon={faBars} />
                    {/* {
                      myNavbar?<div>hidden</div>:<div>block</div>
                    } */}
                    </button>
                </div>
                
            </div>
            <div className={`w-full bg-[#333] ${myNavbar?"hidden":""}`}>
                  <div className="max-w-[340px] mx-auto text-white font-semibold">
                    <ul className="">
                        <li className="pb-6 hover:text-[#ff2e00] transition duration-300">
                            <a href="#company">
                                our company
                            </a>
                        </li>     
                        <li className="pb-6 hover:text-[#ff2e00] transition duration-300">
                            <a href="#services">
                                our services
                            </a>
                        </li>
                        <li className="pb-6">
                            <a href="#process">
                                out process
                            </a>
                        </li>
                        <li className="pb-6">
                            <a href="#contact">
                                contact us
                            </a>
                        </li>
                    </ul>                        
                    </div>
                </div>
        </nav>
    </section>
    </div>
    
    <section id="banner" className='w-full h-[600px]'>
        <div className='h-full w-full relative'>
            <div className='absolute  top-0 left-0 w-full h-full bg-black bg-opacity-50 flex-col flex justify-center items-center text-center lg:px-0 px-[30px]'>
                    <img className='mb-8' src={logoBanner} alt="" />
                    <div className='font-poppins text-[14px] lg:text-[36px] text-white font-bold'>We Will Help Your Business Growing</div>
            </div>
            <img className='w-full h-full object-cover' src={banner} alt="" />
        </div>
    </section>
    <section id="company" className='py-[60px]'>
        <div className='max-w-[340px] lg:max-w-[1100px] mx-auto'>
            <div className='uppercase font-bold text-[#333] font-poppins text-center w-full text-[36px] mb-8'>
                Our Company
            </div>
            <div className='text-center  text-[14px]'>
                    <p className='mb-4'>
                    Technorider is a tech start-up that was founded in 2015 in Indonesia and focused on Internet of Things solutions. We provide end-to-end IoT solutions to help our clients create, digitalize, enhance and upgrade their systems to maximize business operations. Since started, we have worked on many projects from various industrial sectors ranging from telecommunications, solar power generation, mining, green building, agriculture, hospitality, education, and manufacturing.
                    </p>
                </div>
        </div>
    </section>  
    {/* <section className='h-screen relative'>
        <img className='w-full h-full' src={bgSection} alt="" />
        <div className='text-white font-bold font-poppins text-[14px] lg:text-[28px] absolute top-0 w-full flex justify-center items-center h-full py-[60px] '>
            <div className='lg:max-w-[1100px] max-w-[340px] mx-auto text-center'>
                <p className='mb-8'>
                Genesis Digital provides advanced software solutions, design and training.
                </p>
                <p className='mb-8'>
                We are dedicated to delivering the best possible solution for our clients.
                We have extensive experience in database development on many platforms, including desktop, web and mobile.
                </p>
                <p className='mb-8'>
                We have experience using the latest technologies. For Microsoft based solutions this includes C#, .NET, SQL Server. For web based solutions this includes PHP, MySQL, Javascript, AJAX.
                </p>
            </div>
        </div>
    </section> */}
    <section id="services" className='py-[60px]'>
        <div className='w-full mx-auto lg:max-w-[1100px] max-w-[340px]'>
            <div className='uppercase font-bold text-[#333] font-poppins text-center w-full text-[36px] mb-8'>
                        Our services
            </div>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-y-8 lg:gap-x-24'>
                    <div className=' text-center w-full'>
                        <FontAwesomeIcon className='text-[48px] text-[#ff2e00] mb-4 max-w-[340px]' icon={faLaptopCode}></FontAwesomeIcon>
                        <div className='mb-4 text-bold text-[16px] text-[#333] uppercase font-bold font-poppins'>
                            web design
                        </div>
                        <div color='text-[#666] font-[14px]'>
                            We design and build simple landing pages through to advanced ecommerce sites. We adopt industry standard platforms for ease of access and compatability.
                        </div>
                    </div>
                    <div className=' text-center w-full'>
                        <FontAwesomeIcon className='text-[48px] text-[#ff2e00] mb-4' icon={faMobileAndroidAlt}></FontAwesomeIcon>
                        <div className='mb-4 text-bold text-[16px] text-[#333] uppercase font-bold font-poppins'>
                            End-to-End IoT Solutions.
                        </div>
                        <div color='text-[#666] font-[14px]'>
                         Provide end-to-end IoT solutions for you. Start from requirements analysis, system design, prototyping. testing and manufacturing.
                        </div>
                    </div>
                    <div className=' text-center w-full'>
                        <FontAwesomeIcon className='text-[48px] text-[#ff2e00] mb-4' icon={faHandPointUp}></FontAwesomeIcon>
                        <div className='mb-4 text-bold text-[16px] text-[#333] uppercase font-bold font-poppins'>
                            visual ui & ux
                        </div>
                        <div color='text-[#666] font-[14px]'>
                            We design intuituve, engaging and functionally capable user interfaces to make your app or website stand out from the crowd.
                        </div>
                    </div>
                    <div className=' text-center w-full'>
                        <FontAwesomeIcon className='text-[48px] text-[#ff2e00] mb-4' icon={faDatabase}></FontAwesomeIcon>
                        <div className='mb-4 text-bold text-[16px] text-[#333] uppercase font-bold font-poppins'>
                                Business Tailored Solution.
                        </div>
                        <div color='text-[#666] font-[14px]'>
                        We are here to support you to make your ideas come to reality!
                        </div>
                    </div>
            </div>
        </div>
    </section>
    <section id='process' className='py-[60px]'>
        <div className='mx-auto lg:max-w-[1100px] max-w-[340px] rounded-[30px] lg:rounded-[60px] w-full  '>
            <div className=''>
                <div className='uppercase font-bold  font-poppins text-center w-full text-[36px] mb-8'>
                    Our Process
                </div>
                <div className='text-center  text-[14px]'>
                    <p className='mb-4'>
                    For new software to be developed and launched successfully, it is critical that a proven and methodical process is followed.
                    </p>
                    <p className='mb-4'>
                    Our process begins with clear scoping to ensure that client outcomes are clearly understood and documented. This scope forms the basis for developing prototype designs that are both visually engaging and functionally capable. Our highly skilled programmers then take the approved prototype design and develop the codebase to use across the required platforms and systems. Before launching the end product, we perform rigorous testing to ensure our high performance and stability standards are met.
                    </p>
                </div>
                <div className='grid grid-cols-1 lg:grid-cols-5 gap-3 mt-20 '>
                    <div className='w-full  text-white bg-red-500 p-8 text-center relative flex-col flex items-center justify-center'>
                        <div className=' absolute w-full flex top-0 left-0 transform -translate-y-1/2 items-center justify-center'>
                            <div className='w-[60px] h-[60px] bg-red-500 top-0 flex items-center justify-center text-lg font-bold text-white rounded-full border-4 border-white'>
                                <span>1</span>
                            </div>
                        </div>
                        <FontAwesomeIcon className='mt-4 text-[64px] mb-8' icon={faArrowRight} />
                        <div className='tracking-[2px] uppercase  font-bold'>
                            scope
                        </div>
                    </div>
                    <div className='w-full  text-white bg-red-600 p-8 text-center relative flex-col flex items-center justify-center'>
                        <div className=' absolute w-full flex top-0 left-0 transform -translate-y-1/2 items-center justify-center'>
                            <div className='w-[60px] h-[60px] bg-red-600 top-0 flex items-center justify-center text-lg font-bold text-white rounded-full border-4 border-white'>
                                <span>2</span>
                            </div>
                        </div>
                        <FontAwesomeIcon className='mt-4 text-[64px] mb-8' icon={faPaintBrush} />
                        <div className='tracking-[2px] uppercase  font-bold'>
                            design
                        </div>
                    </div>
                    <div className='w-full  text-white bg-red-700 p-8 text-center relative flex-col flex items-center justify-center'>
                        <div className=' absolute w-full flex top-0 left-0 transform -translate-y-1/2 items-center justify-center'>
                            <div className='w-[60px] h-[60px] bg-red-700 top-0 flex items-center justify-center text-lg font-bold text-white rounded-full border-4 border-white'>
                                <span>3</span>
                            </div>
                        </div>
                        <FontAwesomeIcon className='mt-4 text-[64px] mb-8' icon={faCode} />
                        <div className='tracking-[2px] uppercase  font-bold'>
                            Develope
                        </div>
                    </div>
                    <div className='w-full  text-white bg-red-800 p-8 text-center relative flex-col flex items-center justify-center'>
                        <div className=' absolute w-full flex top-0 left-0 transform -translate-y-1/2 items-center justify-center'>
                            <div className='w-[60px] h-[60px] bg-red-800 top-0 flex items-center justify-center text-lg font-bold text-white rounded-full border-4 border-white'>
                                <span>4</span>
                            </div>
                        </div>
                        <FontAwesomeIcon className='mt-4 text-[64px] mb-8' icon={faBullseye} />
                        <div className='tracking-[2px] uppercase  font-bold'>
                            test
                        </div>
                    </div>
                    <div className='w-full  text-white bg-red-900 p-8 text-center relative flex-col flex items-center justify-center'>
                        <div className=' absolute w-full flex top-0 left-0 transform -translate-y-1/2 items-center justify-center'>
                            <div className='w-[60px] h-[60px] bg-red-900 top-0 flex items-center justify-center text-lg font-bold text-white rounded-full border-4 border-white'>
                                <span>5</span>
                            </div>
                        </div>
                        <FontAwesomeIcon className='mt-4 text-[64px] mb-8' icon={faHandsHoldingCircle} />
                        <div className='tracking-[2px] uppercase  font-bold'>
                            launch
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
    
    <section id='contact' className='py-[60px] bg-[#111] relative'>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 mx-auto lg:max-w-[1100px] max-w-[340px] grid-end-auto'>
            <div className='w-full lg:flex items-center lg:justify-start justify-center hidden'>
                <img src={logoSectionContact} alt=""/>
            </div>
            <div className='w-full'>
                <div className='text-white font-poppins'>
                    <p className='mb-4 text-[32px] font-bold'>
                        Have a Project in Mind?
                    </p>
                    <p className='text-white text-[14px]'>
                        Do you have an idea for a project, or a business problem that you think can be solved with a software solution? Please contact us to find out how we can develop a solution that meets your specific needs.
                    </p>
                </div>
                
                <form ref={form} onSubmit={sendEmail}>
                    <div className='grid lg:grid-cols-2 grid-cols-1 my-4 gap-x-4 gap-y-4'>
                        <input className='rounded-xl bg-white p-4 w-full placeholder-[#374355]' type="text" placeholder='Name' name="user_name"/>
                        <input className='rounded-xl bg-white p-4 w-full placeholder-[#374355]' type="text" placeholder='Email' name="user_email"/>
                    </div>
                    <textarea className='rounded-xl bg-white p-4 w-full h-[150px] placeholder-[#374355]' type="" placeholder='Message' name="message"/>
                    <div className='flex justify-end mt-4'>
                        <button className='p-2 text-white lg:w-auto w-full bg-[#ff2e00] rounded-lg ml-0'>Submit</button>
                    </div>
                </form>
            
            </div>
            
        </div>
        
        <img className='absolute bottom-0 mb-[-1px] w-full h-auto  rotate-180'  alt="" />
    </section>
    {/* <section className='py-[30px] bg-[#333] text-white'>
        <div className='grid lg:grid-cols-3 grid-cols-1 gap-y-4 text-center font-bold text-[16px] '>
            <div className=''>info@genesisdigital.asia</div>
            <div className=''>+61 3 9028 7744 (Australia)</div>
            <div className=''>+62 0761 6706770 (Indonesia)</div>
        </div>
    </section> */}
    
    <section className='py-[10px] bg-black text-white font-bold'>
        <div className='text-[12px] text-center w-full'>
            Copyright ©2022 | Technorider
        </div>
    </section> 
</div>
    </>
 
  );
}

export default App;
